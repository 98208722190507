/* You can add global styles to this file, and also import other style files */


/* fallback */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./assets/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

body {
    /* background-image: url('./assets/img/vcar2.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
}

.green-snackbar {
    background: #009d00;
    color: #ffffff;
}

.red-snackbar {
    background: #F44336;
    color: #ffffff;
}


/* table page */

.example-container {
    height: 91%;
    overflow: auto;
}

.filter-input {
    margin-left: 1%;
    margin-top: 1%;
}

button.add-input.mat-fab.mat-button-base.mat-accent {
    float: right;
    margin-right: 1%;
    margin-top: 1%;
}

table {
    margin-left: 1%;
    margin-right: 1%;
    width: 98%;
}

th.mat-sort-header-sorted {
    color: black;
}


/* time input hide clock in dialog */

svg.ng-star-inserted {
    display: none;
}


/* radio button margin */

.form-margin {
    margin-bottom: 15px;
}
